/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
    border-spacing: 0;
    color: #074E8B;
}


html {
  font-size: 100%;
  box-sizing: border-box;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  font-size: 1em;
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
}
body {
  background-color: #ffffff;
  color: #074E8B;
}
::-moz-selection {
    background: #ffe9ec;
}
::selection {
    background: #ffe9ec;
}
.main {
    flex: 1 0 auto;
    margin-top: 4em;
    max-width: 72em;
}
/* HEADER */
.header {
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.2);
  background-color: #1670b8;
  position: fixed;
  width: 100%;
  z-index: 10;
  min-height: 4em;
}
.header .branding {
  display: inline-block;
  min-height: 4em;
}
.header .branding img {
  width: 100px;
  height: 24px;
  width: 6.25em;
  height: 1.5em;
}

/* NAVIGATION */
.nav-toggler {
  position: absolute;
  right: calc(2em - 19px);
  background-color: white;
  top: calc(2em - 19px);
}
.header .navigation {
  display: none;
  width: 100%;
  position: relative;
  padding: 3em 1em;
}

/* MENU */
.menu {
  margin-bottom: 2em;
}
.menu-item a:after {
  transition: background-color .125s ease;
  background: transparent;
  display: block;
  margin: auto;
  margin-top: 0.25em;
  content: "";
  height: 2px;
  width: 100%;
}
.menu-item a:hover:after {
  background-color: #fff;
}
.menu-item {
  display: block;
}
.menu-item a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.menu-item.active a:after {
  display: block;
  margin: auto;
  margin-top: 0.25em;
  content: "";
  width: 100%;
  height: 2px;
}
.menu-item.active a:after {
  background: #fff;
}

/* MENU 2 */
.menu-2 {
  text-align: left;
}
.menu-2-item {
  display: block;
}
.menu-2-item a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
.menu-2-item a:after {
  transition: background-color .125s ease;
  background: transparent;
  display: block;
  margin: auto;
  margin-top: 0.25em;
  content: "";
  height: 2px;
  width: 100%;
}
.menu-2-item .active:after,
.menu-2-item a:hover:after {
  background: #fff
}
.menu-2-item {
  color: #fff;
}
/* MENU LANG */
.menu-lang {
  margin-bottom: 3em;
  margin-left: 0.5em;
}
.menu-lang li {
  display: inline-block;
}
.menu-lang .active a {
  font-weight: 700;
}
/* FOOTER */
footer {
  background-color: #074E8B;
  color: #ffffff;
  flex-shrink: 0;
}
.footer-brand-image {
  width: 150px;
  height: 36px;
}
.no-shrink {
  flex-shrink: 0;
}
.white {
  color: #fff;
}
.light {
  color: #f1f1f1;
}
.green {
  color: #00A14B;
}
.blue {
  color: #074E8B;
}
.whiteboard {
  background-color: #fff;
}
.whiteboard-header {
  display: flex;
  align-items: center;
}
.whiteboard a img:hover {
  transform: scale(1.05);
}

.green-card {
  background-color: #00A14B;
  min-height: 20em;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  position: relative;
  overflow: hidden;
}
.green-card:hover {
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
  background-color: #00a069;
}
.green-card-copy {
    margin: 3em 2em;
}

.cover-image {
    height: 10em;
    width: 100%;
}
/* SLIDER */
.mySlides {
    width: 100%;

}
.slides-control {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -3em;
}

.button {
    border: 2px solid white;
    border-radius: 0.25rem;
    background: #00A14B;
    color: white;
    font-size: 1rem;
    line-height: 0;
    white-space: nowrap;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }
  .button:hover {
      background: #007F3B;
  }
  
  .button:focus {
      outline: 1px solid #fff;
      outline-offset: -3px;
  }
  
  .button:active {
      -webkit-transform: scale(0.99);
              transform: scale(0.99);
  }
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.bg_white {
  background-color: white;
}
.caps {
  text-transform: uppercase;
}
.t-d_n {
    text-decoration: none;
}
.inline {
    display: inline;
}
.block {
    display: block;
}
/* LETTER SPACING */
.l-s_025 {
    letter-spacing: 0.025em;
}
.l-s_050 {
    letter-spacing: 0.05em;
}
/* FONT FAMILIES */
.serif {
    font-family: 'IBM Plex Serif', serif;
}
.condensed {
    font-family: 'IBM Plex Sans Condensed';
}
/* FONT SIZE START */
  
.f-s_9 {
  font-size: 2.887em;
}

.f-s_8 {
  font-size: 2.566em;
}

.f-s_7 {
  font-size: 2.281em;
}

.f-s_6 {
  font-size: 2.027em;
}

.f-s_5 {
  font-size: 1.802em;
}

.f-s_4 {
  font-size: 1.602em;
}

.f-s_3 {
  font-size: 1.424em;
}

.f-s_2 {
  font-size: 1.266em;
}

.f-s_1 {
  font-size: 1.125em;
}

.f-s_0 {
  font-size: 1em;
}

.f-s_-1 {
  font-size: 0.889em;
}

.f-s_-2 {
  font-size: 0.79em;
}

.f-s_-3 {
  font-size: 0.702em;
}
/* FONT SIZE END */
/* LINE HEIGHT START*/

.l-h_0 {
  line-height: 0;
}

.l-h_100 {
    line-height: 1;
}
.l-h_105 {
    line-height: 1.05;
}
.l-h_110 {
    line-height: 1.1;
}
.l-h_115{
    line-height: 1.15;
}
.l-h_120{
    line-height: 1.2;
}
.l-h_125{
    line-height: 1.25;
}
.l-h_130{
    line-height: 1.3;
}
.l-h_135{
    line-height: 1.35;
}
.l-h_140{
    line-height: 1.4;
}
.l-h_145{
    line-height: 1.45;
}
.l-h_150{
    line-height: 1.5;
}
/* LINE HEIGHT END*/
/* FONT WEIGHT START */

.f-w_300 {
  font-weight: 300;
}

.f-w_400 {
  font-weight: 400;
}

.f-w_500 {
  font-weight: 500;
}

.f-w_600 {
  font-weight: 600;
}

.f-w_700 {
  font-weight: 700;
}
/* FONT WEIGHT END */
/* TEXT ALIGN START */
.t-a_l {
    text-align: left;
}
.t-a_r {
    text-align: right;
}
.t-a_c {
    text-align: center;
}
/* TEXT ALIGN END */
/* MARGIN TOP */
.m-t_025 {
    margin-top: 0.25em;
}
.m-t_050 {
    margin-top: 0.5em;
} 
.m-t_075 {
    margin-top: 0.75em;
}
.m-t_100 {
    margin-top: 1em;
}
.m-t_125 {
    margin-top: 1.25em;
}
.m-t_150 {
    margin-top: 1.5em;
}
.m-t_175 {
    margin-top: 1.75em;
}
.m-t_200 {
    margin-top: 2em;
}
.m-t_225 {
    margin-top: 2.25em;
}
.m-t_250 {
    margin-top: 2.5em;
}
.m-t_275 {
    margin-top: 2.75em;
}
.m-t_300 {
    margin-top: 3em;
}
/* MARGIN RIGHT */
.m-r_025 {
    margin-right: 0.25em;
}
.m-r_050 {
    margin-right: 0.5em;
} 
.m-r_075 {
    margin-right: 0.75em;
}
.m-r_100 {
    margin-right: 1em;
}
.m-r_125 {
    margin-right: 1.25em;
}
.m-r_150 {
    margin-right: 1.5em;
}
.m-r_175 {
    margin-right: 1.75em;
}
.m-r_200 {
    margin-right: 2em;
}
.m-r_225 {
    margin-right: 2.25em;
}
.m-r_250 {
    margin-right: 2.5em;
}
.m-r_275 {
    margin-right: 2.75em;
}
.m-r_300 {
    margin-right: 3em;
}
/* MARGIN BOTTOM */
.m-b_025 {
    margin-bottom: 0.25em;
}
.m-b_050 {
    margin-bottom: 0.5em;
} 
.m-b_075 {
    margin-bottom: 0.75em;
}
.m-b_100 {
    margin-bottom: 1em;
}
.m-b_125 {
    margin-bottom: 1.25em;
}
.m-b_150 {
    margin-bottom: 1.5em;
}
.m-b_175 {
    margin-bottom: 1.75em;
}
.m-b_200 {
    margin-bottom: 2em;
}
.m-b_225 {
    margin-bottom: 2.25em;
}
.m-b_250 {
    margin-bottom: 2.5em;
}
.m-b_275 {
    margin-bottom: 2.75em;
}
.m-b_300 {
    margin-bottom: 3em;
}
/* MARGIN LEFT */
.m-l_025 {
    margin-left: 0.25em;
}
.m-l_050 {
    margin-left: 0.5em;
} 
.m-l_075 {
    margin-left: 0.75em;
}
.m-l_100 {
    margin-left: 1em;
}
.m-l_125 {
    margin-left: 1.25em;
}
.m-l_150 {
    margin-left: 1.5em;
}
.m-l_175 {
    margin-left: 1.75em;
}
.m-l_200 {
    margin-left: 2em;
}
.m-l_225 {
    margin-left: 2.25em;
}
.m-l_250 {
    margin-left: 2.5em;
}
.m-l_275 {
    margin-left: 2.75em;
}
.m-l_300 {
    margin-left: 3em;
}
/* MARGIN */
.m_025 {
    margin: 0.25em;
}
.m_050 {
    margin: 0.5em;
} 
.m_075 {
    margin: 0.75em;
}
.m_100 {
    margin: 1em;
}
.m_125 {
    margin: 1.25em;
}
.m_150 {
    margin: 1.5em;
}
.m_175 {
    margin: 1.75em;
}
.m_200 {
    margin: 2em;
}
.m_225 {
    margin: 2.25em;
}
.m_250 {
    margin: 2.5em;
}
.m_275 {
    margin: 2.75em;
}
.m_300 {
    margin: 3em;
}
/* MARGIN X (RIGHT & LEFT) */
.m-x_auto {
    margin-right: auto;
    margin-left: auto;
}
.m-x_025 {
    margin-right: 0.25em;
    margin-left: 0.25em;
}
.m-x_050 {
    margin-right: 0.5em;
    margin-left: 0.5em;
} 
.m-x_075 {
    margin-right: 0.75em;
    margin-left: 0.75em;
}
.m-x_100 {
    margin-right: 1em;
    margin-left: 1em;
}
.m-x_125 {
    margin-right: 1.25em;
    margin-left: 1.25em;
}
.m-x_150 {
    margin-right: 1.5em;
    margin-left: 1.5em;
}
.m-x_175 {
    margin-right: 1.75em;
    margin-left: 1.75em;
}
.m-x_200 {
    margin-right: 2em;
    margin-left: 2em;
}
.m-x_225 {
    margin-right: 2.25em;
    margin-left: 2.25em;
}
.m-x_250 {
    margin-right: 2.5em;
    margin-left: 2.5em;
}
.m-x_275 {
    margin-right: 2.75em;
    margin-left: 2.75em;
}
.m-x_300 {
    margin-right: 3em;
    margin-left: 3em;
}
/* MARGIN Y (TOP & BOTTOM) */
.m-y_025 {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
.m-y_050 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
} 
.m-y_075 {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
.m-y_100 {
    margin-top: 1em;
    margin-bottom: 1em;
}
.m-y_125 {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}
.m-y_150 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
.m-y_175 {
    margin-top: 1.75em;
    margin-bottom: 1.75em;
}
.m-y_200 {
    margin-top: 2em;
    margin-bottom: 2em;
}
.m-y_225 {
    margin-top: 2.25em;
    margin-bottom: 2.25em;
}
.m-y_250 {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
}
.m-y_275 {
    margin-top: 2.75em;
    margin-bottom: 2.75em;
}
.m-y_300 {
    margin-top: 3em;
    margin-bottom: 3em;
}
/* PADDING TOP */ 
.p-t_025 {
    padding-top: 0.25em;
}
.p-t_050 {
    padding-top: 0.5em;
} 
.p-t_075 {
    padding-top: 0.75em;
}
.p-t_100 {
    padding-top: 1em;
}
.p-t_125 {
    padding-top: 1.25em;
}
.p-t_150 {
    padding-top: 1.5em;
}
.p-t_175 {
    padding-top: 1.75em;
}
.p-t_200 {
    padding-top: 2em;
}
.p-t_225 {
    padding-top: 2.25em;
}
.p-t_250 {
    padding-top: 2.5em;
}
.p-t_275 {
    padding-top: 2.75em;
}
.p-t_300 {
    padding-top: 3em;
}
/* PADDING RIGHT */ 
.p-r_025 {
    padding-right: 0.25em;
}
.p-r_050 {
    padding-right: 0.5em;
} 
.p-r_075 {
    padding-right: 0.75em;
}
.p-r_100 {
    padding-right: 1em;
}
.p-r_125 {
    padding-right: 1.25em;
}
.p-r_150 {
    padding-right: 1.5em;
}
.p-r_175 {
    padding-right: 1.75em;
}
.p-r_200 {
    padding-right: 2em;
}
.p-r_225 {
    padding-right: 2.25em;
}
.p-r_250 {
    padding-right: 2.5em;
}
.p-r_275 {
    padding-right: 2.75em;
}
.p-r_300 {
    padding-right: 3em;
}
/* PADDING BOTTOM */
.p-b_025 {
    padding-bottom: 0.25em;
}
.p-b_050 {
    padding-bottom: 0.5em;
} 
.p-b_075 {
    padding-bottom: 0.75em;
}
.p-b_100 {
    padding-bottom: 1em;
}
.p-b_125 {
    padding-bottom: 1.25em;
}
.p-b_150 {
    padding-bottom: 1.5em;
}
.p-b_175 {
    padding-bottom: 1.75em;
}
.p-b_200 {
    padding-bottom: 2em;
}
.p-b_225 {
    padding-bottom: 2.25em;
}
.p-b_250 {
    padding-bottom: 2.5em;
}
.p-b_275 {
    padding-bottom: 2.75em;
}
.p-b_300 {
    padding-bottom: 3em;
}
/* PADDING LEFT */
.p-l_025 {
    padding-left: 0.25em;
}
.p-l_050 {
    padding-left: 0.5em;
} 
.p-l_075 {
    padding-left: 0.75em;
}
.p-l_100 {
    padding-left: 1em;
}
.p-l_125 {
    padding-left: 1.25em;
}
.p-l_150 {
    padding-left: 1.5em;
}
.p-l_175 {
    padding-left: 1.75em;
}
.p-l_200 {
    padding-left: 2em;
}
.p-l_225 {
    padding-left: 2.25em;
}
.p-l_250 {
    padding-left: 2.5em;
}
.p-l_275 {
    padding-left: 2.75em;
}
.p-l_300 {
    padding-left: 3em;
}
/* PADDING */ 
.p_025 {
    padding: 0.25em;
}
.p_050 {
    padding: 0.5em;
} 
.p_075 {
    padding: 0.75em;
}
.p_100 {
    padding: 1em;
}
.p_125 {
    padding: 1.25em;
}
.p_150 {
    padding: 1.5em;
}
.p_175 {
    padding: 1.75em;
}
.p_200 {
    padding: 2em;
}
.p_225 {
    padding: 2.25em;
}
.p_250 {
    padding: 2.5em;
}
.p_275 {
    padding: 2.75em;
}
.p_300 {
    padding: 3em;
}
/* PADDING X (RIGHT & LEFT) */
.p-x_025 {
    padding-right: 0.25em;
    padding-left: 0.25em;
}
.p-x_050 {
    padding-right: 0.5em;
    padding-left: 0.5em;
} 
.p-x_075 {
    padding-right: 0.75em;
    padding-left: 0.75em;
}
.p-x_100 {
    padding-right: 1em;
    padding-left: 1em;
}
.p-x_125 {
    padding-right: 1.25em;
    padding-left: 1.25em;
}
.p-x_150 {
    padding-right: 1.5em;
    padding-left: 1.5em;
}
.p-x_175 {
    padding-right: 1.75em;
    padding-left: 1.75em;
}
.p-x_200 {
    padding-right: 2em;
    padding-left: 2em;
}
.p-x_225 {
    padding-right: 2.25em;
    padding-left: 2.25em;
}
.p-x_250 {
    padding-right: 2.5em;
    padding-left: 2.5em;
}
.p-x_275 {
    padding-right: 2.75em;
    padding-left: 2.75em;
}
.p-x_300 {
    padding-right: 3em;
    padding-left: 3em;
}
/* PADDING Y (TOP & BOTTOM) */
.p-y_025 {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
.p-y_050 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
} 
.p-y_075 {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}
.p-y_100 {
    padding-top: 1em;
    padding-bottom: 1em;
}
.p-y_125 {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
}
.p-y_150 {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.p-y_175 {
    padding-top: 1.75em;
    padding-bottom: 1.75em;
}
.p-y_200 {
    padding-top: 2em;
    padding-bottom: 2em;
}
.p-y_225 {
    padding-top: 2.25em;
    padding-bottom: 2.25em;
}
.p-y_250 {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
}
.p-y_275 {
    padding-top: 2.75em;
    padding-bottom: 2.75em;
}
.p-y_300 {
    padding-top: 3em;
    padding-bottom: 3em;
}
/* LEGIBLE */

.legible *+*:not(p):not(li):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.header):not(.exclude):not(figcaption):not(ul):not(ol):not(hr):not(table):not(input):not(form) {
    margin: 2.8em auto;
}

.legible h1:not(.legible-reset),
.legible h2,
.legible h3,
.legible h4,
.legible h5,
.legible h6 {
    color: #074E8B;
    font-size: 1.125em;
    line-height: 1.3;
    font-weight: 700;
    margin-top: 2.8rem;
    margin-right: 0.75rem;
    margin-left: 1rem;
    margin-bottom: 1.4rem;
}
.legible h2{
    color: #074E8B;
    font-size: 1.802em;
    line-height: 1.3;
    font-weight: 700;
    margin-top: 0;
    margin-right: 0.75rem;
    margin-left: 1rem;
    margin-bottom: 1.4rem;
}

.legible p {
    margin-top: 1.4em;
    line-height: 1.4em;
    margin-left: 1em;
    margin-right: 0.75em;
    color: #074E8B;
}

.legible ul,
.legible ol {
    padding-left: 1.4em;
    margin-top: 2.8em;
    margin-bottom: 2.8em;
    margin-left: 1em;
    margin-right: 0.75em;
}
.legible ul {
    list-style-type: circle;
}
.legible li ul {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    margin-left: 0;
}
.legible h1 + ul,
.legible h2 + ul,
.legible h3 + ul,
.legible h4 + ul,
.legible h5 + ul,
.legible h6 + ul {
    margin-top: 0.5em;
}

.legible li {
    margin-top: 0.3em;
    line-height: 1.4em;
}

.legible figcaption {
    font-size: 0.702em;
    color: #ffffff;
    line-height: 1.4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 0.5em;
    background: #074E8B;
}

table {
    margin: 2.8em 0.5em;
    padding: 1em;
    width: calc(100% - 1em);
    max-width: 32em;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.legible img {
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 100%;
    height: auto !important;
}

.legible hr {
    background-color: transparent;
    border-top: 1px dashed;
    margin: 2.8em 1em;
}
.legible strong {
    font-weight: 700;
}
.legible p a,
.legible li a {
    color: inherit;
    text-decoration: none;
    text-shadow: 0.04545em 0 0 #fff, 0.09091em 0 0 #fff, 0.13636em 0 0 #fff, -0.04545em 0 0 #fff, -0.09091em 0 0 #fff, -0.13636em 0 0 #fff, 0 0.04545em 0 #fff, 0 0.09091em 0 #fff, 0 -0.04545em 0 #fff, 0 -0.09091em 0 #fff;
    background-image: linear-gradient(#00A14B, #00A14B);
    background-position: 0 1.23em;
    background-size: 2px 1px;
    background-repeat: repeat-x;
}
/* #007F3B */
.legible p a:hover,
.legible li a:hover {
    background-image: linear-gradient(#007F3B, #007F3B); 
}
.legible p a::-moz-selection,
.legible li a::-moz-selection {
    text-shadow: 0.04545em 0 0 #ffe9ec, 0.09091em 0 0 #ffe9ec, 0.13636em 0 0 #ffe9ec, -0.04545em 0 0 #ffe9ec, -0.09091em 0 0 #ffe9ec, -0.13636em 0 0 #ffe9ec, 0 0.04545em 0 #ffe9ec, 0 0.09091em 0 #ffe9ec, 0 -0.04545em 0 #ffe9ec, 0 -0.09091em 0 #ffe9ec;
    background: #ffe9ec;
}
.legible p a::selection,
.legible li a::selection {
    text-shadow: 0.04545em 0 0 #ffe9ec, 0.09091em 0 0 #ffe9ec, 0.13636em 0 0 #ffe9ec, -0.04545em 0 0 #ffe9ec, -0.09091em 0 0 #ffe9ec, -0.13636em 0 0 #ffe9ec, 0 0.04545em 0 #ffe9ec, 0 0.09091em 0 #ffe9ec, 0 -0.04545em 0 #ffe9ec, 0 -0.09091em 0 #ffe9ec;
    background: #ffe9ec;
}

.legible p a[target="_blank"]:after,
.legible li a[target="_blank"]:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiBmaWxsPSIjMDBCQzdCIiBkYXRhLWlkPSJnZW9taWNvbi1leHRlcm5hbCIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNNCA0IEwxMiA0IEwxMiA4IEw4IDggTDggMjQgTDI0IDI0IEwyNCAyMCBMMjggMjAgTDI4IDI4IEw0IDI4IHogTTE2IDQgTDI4IDQgTDI4IDE2IEwyNCAxMiBMMTYgMjAgTDEyIDE2IEwyMCA4eiIvPjwvc3ZnPg==);
    margin-left: 0.25em;
}
/* LEGIBLE TYPE FOOTER */
.legible-type-footer {
    font-size: 0.889em;
}
.legible-type-footer *+*:not(p):not(li):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.header):not(.exclude):not(figcaption):not(ul):not(ol):not(hr):not(table) {
    margin: 2.8em auto;
}

.legible-type-footer h1:not(.legible-reset),
.legible-type-footer h2,
.legible-type-footer h3,
.legible-type-footer h4,
.legible-type-footer h5,
.legible-type-footer h6 {
    font-size: 1em;
    line-height: 1.3;
    font-weight: 700;
    margin-top: 2.8em;
    margin-bottom: 1.4em;
}

.legible-type-footer p {
    margin-top: 1.4em;
    line-height: 1.4em;
}

.legible-type-footer ul,
.legible-type-footer ol {
    padding-left: 1.4em;
    margin-top: 2.8em;
    margin-bottom: 2.8em;
}

.legible-type-footer h1 + ul,
.legible-type-footer h2 + ul,
.legible-type-footer h3 + ul,
.legible-type-footer h4 + ul,
.legible-type-footer h5 + ul,
.legible-type-footer h6 + ul {
    margin-top: 0;
}

.legible-type-footer ul {
    list-style-type: circle;
}

.legible-type-footer li {
    margin-top: 0.3em;
    line-height: 1.4em;
}

.legible-type-footer figcaption {
    font-size: 0.702em;
    color: #ffffff;
    line-height: 1.4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    background: #074E8B;
}

table {
    margin: 2.8em 0.5em;
    padding: 1em;
    width: calc(100% - 1em);
    max-width: 32em;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.legible-type-footer img {
    height: auto !important;
    display: block;
    max-width: 100%;
    height: auto !important;
}

.legible-type-footer hr {
    background-color: transparent;
    border-top: 1px dashed;
    margin: 2.8em 1em;
}
.legible-type-footer strong {
    font-weight: 700;
}
/* LEGIBLE TYPE 1*/
.legible-type-1 *+*:not(p):not(li):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.header):not(.exclude):not(figure):not(ul):not(ol):not(hr) {
    margin: 2.8em auto;
}

.legible-type-1 h1,
.legible-type-1 h2,
.legible-type-1 h3,
.legible-type-1 h4,
.legible-type-1 h5,
.legible-type-1 h6 {
    font-size: 1.424em;
    line-height: 1.3;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1.4rem;
}

.legible-type-1 p {
    margin-top: 1.4em;
    line-height: 1.4em;
    margin-bottom: 1.4em;
    color: #074E8B;
}

.legible-type-1 ul,
.legible-type-1 ol {
    padding-left: 1.4em;
    margin-top: 2.8em;
    margin-bottom: 2.8em;
    margin-left: 1em;
    margin-right: 0.75em;
}

.legible-type-1 li {
    margin-top: 1.4em;
    line-height: 1.4em;
}

.legible-type-1 figure {
    margin-right: 0.25em;
    display: inline-block;
}

.legible-type-1 figcaption {
    font-size: 0.702em;
    color: #ffffff;
    line-height: 1.4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 0.5em;
    background: black;
}

/*.legible-type-1 img {
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}*/

.legible-type-1 hr {
    background-color: transparent;
    border-top: 1px dashed;
    margin: 2.8em 1em;
}
.legible-type-1 em {
    font-style: italic;
}

/* TABLE */
th {
  border-bottom: 2px solid #074E8B;
  padding: 0.5em;
}
td {
  padding: 0.5em;
  border-bottom: 1px solid #074E8B;
}
tr td:first-of-type {
  font-weight: 500;

}
tr th {
  font-weight: 600;
  color: #074E8B;
}
tr th:first-child {
    width: 32%;
  }
tr th:nth-child(2n) {
    width: 34%;
  }
tr th:nth-child(3n) {
    width: 34%;
  }

  /* TIME TABLE  & PRICING TABLE */
  .time-table {
      background-color: rgba(175, 238, 238, 0.5);
  }
  .pricing-table {
      background-color: hsla(120, 93%, 79%, 0.5);
  }
  .time-table,
  .pricing-table {
      padding-top: 3em;
      padding-bottom: 2em;
  }
  .time-table h3,
  .pricing-table h3 {
      margin-left: 1rem;
      font-size: 1.266em;
      text-transform: uppercase;
      font-weight: 700;
      color: #074E8B;
  }
  .time-table p,
  .pricing-table p {
      font-weight: 500;
      font-size: 0.889em;
      margin-top: 1.4em;
      margin-left: 1rem;
      margin-right: 1rem;
      line-height: 1.3em;
  }